import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "D:/DEV02/Progetti/torredienna_web/src/components/layout.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faShare, faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import SEO from "../components/seo.js";
import * as Container from "../components/container.js";
import * as Hero from "../components/hero.js";
import * as Section from "../components/splitsections.js";
import { Button } from "../components/button.js";
import Card from "../components/card.js";
import Anchor from "../components/anchor.js";
import Title from "../components/title.js";
import { Prezzo, Sconto, Disponibile, Spedizione } from "../components/bookDetails.js";
import { Bio, Col } from "../components/bio.js";
import * as Form from "../components/form.js";
import Phone from "../components/phone.js";
import Player from "../components/audioplayer.js";
import Citazione from "../components/citazione.js";
import { Footer, Copyright, Patamu } from "../components/footer.js";
import { ReadMore } from "../components/readMore";
import ImageGallery from "../components/imageGallery";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEO mdxType="SEO" />
    <Hero.FullWidth id="ID_Hero" background="#f2ba4d" customH1 h2="Antico osservatorio astronomico-geodetico e templum celeste di Sicilia" textColor="#2c2e31" imageSlider />
    <Container.Fluid id="progetto" className={"pt-4 pb-2"} background={"#ebe7db"} color={"#2c2e31"}>
      <Section.FullWidth>
        <Title type={"h3"} mdxType="Title">Il Progetto di Ricerca</Title>
        <hr></hr>
      </Section.FullWidth>
      <Section.One className={"pr-lg-0"}>
        <p>{`Il nostro `}<strong>{`progetto di ricerca`}</strong>{` è incentrato sullo studio della `}<strong>{`Torre Ottagonale di Enna`}</strong>{` al fine di indagare le sue `}<strong>{`misteriose origini`}</strong>{`. Ci siamo avvalsi di un `}<strong>{`approccio di analisi multidisciplinare`}</strong>{` che integra `}<strong>{`storia, topografia, archeologia, archeoastronomia ed informatica`}</strong>{`. Gli interrogativi principali che ci siamo posti all’inizio della nostra ricerca sono:`}</p>
        <ul>
          <li><strong>Chi ha costruito la Torre Ottagonale?</strong></li>
          <li><strong>Quali sono le sue funzioni originarie?</strong></li>
          <li><strong>Perché Enna è stata scelta come sito</strong> per la fondazione di questo magnifico edificio?</li>
        </ul>
        <p>{`L’`}<strong>{`obiettivo`}</strong>{` è fornire `}<strong>{`risposte chiare`}</strong>{` a queste domande, risposte opportunamente documentate e verificabili scientificamente, a sostegno della nostra tesi di partenza, ovvero che la `}<strong>{`Torre Ottagonale di Enna`}</strong>{` costituisca in verità un `}<strong>{`centro di osservazione astronomica`}</strong>{` utile a definire un sistema di `}<strong>{`ripartizione territoriale`}</strong>{` fondato oltre `}<strong>{`3500 anni fa`}</strong>{`.`}</p>
        <p>{`Secondo questa teoria, la `}<strong>{`Torre Ottagonale di Enna`}</strong>{` fu fondata dai `}<strong>{`Siculi`}</strong>{` tra il `}<strong>{`1100 e il 1000 a.C.`}</strong>{`, un’epoca precedente allo stanziamento in Sicilia di comunità di cultura greca. Piuttosto, l’`}<strong>{`altopiano di Enna`}</strong>{` fu individuato come il “`}<strong>{`centro sacrale`}</strong>{`”, riflesso terrestre di un modello teorico della volta celeste, già molti secoli prima, quando i `}<strong>{`Sicani`}</strong>{` abitavano i territori dell'entroterra siciliano.`}</p>
        <p>{`La `}<strong>{`Torre Ottagonale`}</strong>{` è l’`}<em><strong>{`Umbilicus Siciliae et Trinakriae`}</strong></em>{`, l’`}<em><strong>{`arx auguraculum`}</strong></em>{` da cui una figura sacerdotale italica avrebbe interrogato la volta celeste per la fondazione del `}<strong>{`Templum`}</strong>{`, centro gromatico alla base dell’intero sistema insediativo dell’Isola e del mondo allora conosciuto.`}</p>
      </Section.One>
      <Section.Two className={"py-3 py-lg-0"} style={{
        alignSelf: "start!important"
      }}>
        <p>{`La `}<strong>{`Torre Ottagonale di Enna`}</strong>{` rappresenta così il più antico sito del Mediterraneo - e forse l’unico al mondo - per l’`}<strong>{`osservazione astronomica`}</strong>{`, la `}<strong>{`divinazione`}</strong>{` e l’`}<strong>{`agrimensura`}</strong>{`, riconducibile alla messa in pratica di antichissime conoscenze e tecniche di matrice `}<strong>{`egeo-anatolica`}</strong>{` importate nell'Isola dalla civiltà `}<strong>{`sicana`}</strong>{` e successivamente evolute dai `}<strong>{`Siculi`}</strong>{` intorno al `}<strong>{`XI secolo a.C.`}</strong>{`.`}</p>
        <p>{`Il nostro `}<strong>{`progetto di ricerca`}</strong>{` ambisce all’`}<strong>{`opportunità straordinaria`}</strong>{` di comprendere le dinamiche di `}<strong>{`ibridazione sociale, culturale, politica ed economica`}</strong>{` che coinvolsero le antiche comunità siciliane - tra cui `}<strong>{`Sicani, Siculi ed Elimi`}</strong>{` - che prosperarono in Sicilia durante l’`}<strong>{`età del Bronzo`}</strong>{`.`}</p>
        <p>{`Noi autori di questa ricerca vogliamo dunque far luce sulla `}<strong>{`Storia obliata di Henna`}</strong>{` ed introdurne un nuovo capitolo attraverso la rilettura delle fonti antiche, la scoperta di documenti inediti ed il riconoscimento di alcuni reperti archeologici. Condividiamo con entusiasmo le nostre `}<strong>{`scoperte`}</strong>{` con il lettore, con l'obiettivo di fornire nuove interpretazioni di eventi, manufatti e monumenti e così raggiungere nuove prospettive di ricerca future.`}</p>
        <p>{`Il nostro progetto di ricerca sulla `}<strong>{`Torre Ottagonale di Enna`}</strong>{` costituisce un concreto contributo intellettuale e scientifico per sanare l’`}<strong>{`ingiustizia`}</strong>{` a cui questo monumento e l’intera città di Enna sono sottoposti da tempo. Tutto ciò dimostra come sia possibile, attraverso la collaborazione tra professionisti indipendenti, dare impulso alla valorizzazione e al riconoscimento della città di Enna come `}<strong>{`Patrimonio Mondiale dell'Umanità`}</strong>{`.`}</p>
        <p>{`Ha collaborato con noi attivamente anche il team di `}<strong>{`Eco Astronomy Sri Lanka`}</strong>{`. Fondato nel `}<strong>{`2018`}</strong>{`, questo istituto è un leader in Sri Lanka per la ricerca astronomica multidisciplinare.`}</p>
      </Section.Two>
      <Section.FullWidth className="text-center mt-4">
  <p style={{
          fontSize: '1.3rem',
          fontWeight: 'bold',
          margin: '0 auto',
          maxWidth: '650px',
          lineHeight: '1.6'
        }}>
    Scopri la lista sempre aggiornata di chi, con i fatti, ha sostenuto il nostro progetto di ricerca, dimostrando con grande passione e impegno il proprio livello culturale, per riscrivere insieme una nuova pagina della storia di Enna e della Sicilia!
  </p>
  <div className="d-flex justify-content-center w-100 align-items-center">
    <Button solid inline large targetBlank color="#db4437" icon={faShare} href="https://docs.google.com/document/d/13KWQ-ehFYkzIvNSW4iJbkz_NSmvmmuyvVsqZAn624Jk/edit?usp=sharing" className="mt-3 mb-1" mdxType="Button">
      Consulta La Lista
    </Button>
  </div>
      </Section.FullWidth>
      <Section.FullWidth>
        <br />
        <br />
        <br />
        <Title type={"h3"} mdxType="Title">Gli autori del progetto di ricerca</Title>
        <hr></hr>
      </Section.FullWidth>
      <Container.Fixed id="autori" className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="PaoloDangelo.jpg" alt="Paolo D’angelo" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Paolo D’angelo (Ennese)</b></div>
            <div className={"pb-2"}><b><i>Archeologo e topografo specializzato in topografia antica</i></b> svolge attività di ricerca come libero professionista iscritto all'Elenco del MiC dei 'Professionisti dei Beni Culturali'.<br /> <b>Fondatore di ArcheoSIT</b></div>
            <ul>
              <li><b>Docente</b> del <b>MiUR</b> di storia dell'arte</li>
              <li><b>Presidente</b> della <b>Sezione</b> provinciale di <b>Enna</b> dell'<b>Associazione Italiana Insegnanti di Geografia</b></li>
              <li>Membro della <b>Società Ennese di Storia Patria</b></li>
              <li><b>Consulente</b> tecnico d'ufficio del <b>Tribunale di Enna</b></li>
              <li><b>Autore e copywriter</b> di contenuti archeologici per il web e riviste</li>
            </ul>
          </Col>
        </Bio>
      </Container.Fixed>
      <Container.Fixed className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="FabrizioSeverino.jpg" alt="Fabrizio Natanaele Severino" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Fabrizio Natanaele Severino (Ennese)</b></div>
            <div className={"pb-2"}><b><i>Informatico e problem solver di comprovata esperienza</i></b> svolge consulenze tecniche e strategiche per imprenditori e manager.<br /> <b>Fondatore di Zenit Security</b>, un'attività professionale specializzata in:</div>
            <ul>
              <li><b>Consulenza informatica e risoluzione di problemi complessi</b></li>
              <li><b>Integrazione di sistemi</b></li>
              <li><b>Progettazione, realizzazione e ottimizzazione di infrastrutture di rete</b>, pensate anche per il lavoro remoto</li>
              <li><b>Sviluppo di software personalizzato</b></li>
              <li><b>Manutenzione e monitoraggio proattivo dei sistemi</b></li>
              <li><b>Automazione dei processi digitali</b></li>
              <li><b>Sicurezza informatica tailor-made</b>, per rispondere alle specifiche esigenze dei clienti
              </li>
            </ul>
          </Col>
        </Bio>
      </Container.Fixed>
      <Container.Fixed id="severino" className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="AngeloSeverino.jpg" alt="Angelo Severino" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Angelo Severino (Ennese)</b></div>
            <div className={"pb-2 h1"}><b>(n. 1947, m. 2022)</b></div>
            <b>Giornalista investigativo, pubblicista e storico.</b> Ha collaborato con 'La Sicilia' e la 'Gazzetta del Sud'. Ha condotto numerose inchieste investigative e ricerche storiche sulla città di Enna, tra le più rinomate ci sono la ex miniera di Pasquasia (documentata agli <i>atti parlamentari, p. 541, XVII legislatura, allegato B ai resoconti</i>) e l'indagine storica sulla Torre Ottagonale di Enna.
          </Col>
        </Bio>
      </Container.Fixed>
      <Container.Fixed className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="UmbertoMassocco.jpg" alt="Umberto Massocco" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Umberto Massocco (Palermitano)</b></div>
            <div className={"pb-2 h1"}><b>(n. 1903, m. 1985)</b></div>
            <b><i>Colonnello del Genio Civile e Militare ed Ispettore Onorario alle Antichità di 52 Comuni Siciliani.</i></b> I suoi studi hanno permesso intorno agli anni ‘60 di individuare nella Torre Ottagonale di Enna l’<i>Umbilicus Siciliae</i>, ossia il centro del sistema dei cardi e del decumano costituenti gli allineamenti topografici lungo i quali si sviluppò la rete stradale della Sicilia antica.
          </Col>
        </Bio>
      </Container.Fixed>
    </Container.Fluid>
    <Container.Fluid id="libro" background={"#2c2e31"}>
      <Title type="h3" as="h2" className="py-4 px-3" style={{
        textAlign: "center",
        width: "100%",
        color: "#fff"
      }} mdxType="Title">
Nuovo Libro UMBILICUS SICILIAE ET TRINAKRIAE:<br /> La Torre Ottagonale di Enna
      </Title>
      <Card withSections mdxType="Card">
        <Section.FullWidth className={"py-5"} style={{
          textAlign: "center"
        }}>
          <div style={{
            "maxWidth": "800px",
            "margin": "0 auto"
          }}>
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "1040px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "68.46153846153847%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADrUlEQVQ4y0WT+0/bVRjG+08Y9Rd/MXOLZhpjNtQpjskGIsMBLtuye3QLgQVwEkJkEgbRbTAuw7CBCgiZzMG4bRLoQCiEUui4FXrh0nJpS4VyKfT2Ld8WPqbf6XySNydv3pPPec6Tc2ROazeLo2WYR8rYmGthy+dhymhhwRwsGza7k0Xr3+gnDBisywg+H4LXi8fjweVy4Xa7EQRB6j1uD7KpvkIeFXxMQ2EYQ0+u4nFvkp6RwYSyksWJh/zV9jODJVnouhQoFSpEl4ugtgMBCeT3+6Xe5/Oxvr6OzGKx0doqp7OzG4NhGpfbx/fpXzE03Mv8nBpFbxnq5mtY7t1hPfZzhMw0rNPTGE2zzJpMzM3OYrPZJIcOhwPZxPg48vZ2OuRyBgcGcDk3uZSSyp/KUUamFunp62RI34454xt24qPxRB1iqL6OPrWaQZWKZ2q15Mwn/OsweEJLczOP6uoYGRlmWwxw9Nxxfqypol2l4VpeKrWNNbTVPWE4Lgrv79WYbTb0Wh0z09NoxsawmM3S9SWgyWSSQMGBXq8nsCUSe+E0CYX5lD3u5kpOIr9WFnC7sZNb9bUEgKWlZaYmJ6X9wXXD4fg/wx6FgpHhUfS6SeTtbQhegaSURPJ+KedW9QOSshK5c+8G+XeLqP2jhG2/iMVsYdJgQKvVYpyZkWAvgAtmM8bxCYyjYxjNZvxigISEc3yXeZ7s3DSKCq5TWZhDZmI8BdlJ+AM76LRa+pVKKb9+ZT8GvR6v14sjCNzehg27npX5Hlhewb0lcvZEHFeiP+Tm2XiKzx8n89gRznz0NkXpF8Hvxm63S84WrVY2Nzel9/giw6BVETApuum6cZPq6hrC3t9L+ulQcpMjycuIpjjnS6pux5ObGsrJyP0MP1PxXDv8J1EUnwODZFd7K2NFhXRU1/D0fiW/FX6NcaAA43gb5VXFnLp8kmMx0YS88y67X36JipIfJNbq6ioupwtxS8TpdLK2tobMWVqMK3QfvlOxrH0RQW9FJRqthsaGh8TExLH7rb3s2vMmH4SHcPDoe4SE7OHT8H3MLcxK3y4IcrvcCG6BLZ+IzF5+l42IT/DERLASeZCn+Xm0dXSSkvwtr736CrveeJ0D+w8QnhZGVMVhPis9QuRPhzlTe4JL9Re4XH+Rq4+TSWlJQjHVhcwrCKwNqlh+cB/74ADr6w4mNDquZ+eQmZFBS1MTc0YTi6tWDEt6xiwjqOaVtOlaadI0UKOuokxZSo48i8HZAf4BG6925U87K98AAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "UMBILICUS SICILIAE ET TRINAKRIAE La Torre Ottagonale di Enna",
                  "title": "UMBILICUS SICILIAE ET TRINAKRIAE La Torre Ottagonale di Enna",
                  "src": "/static/d6bfeeee7e411168fcd3f931a11dcf3f/1ff84/mockup_libri.png",
                  "srcSet": ["/static/d6bfeeee7e411168fcd3f931a11dcf3f/86c28/mockup_libri.png 260w", "/static/d6bfeeee7e411168fcd3f931a11dcf3f/69902/mockup_libri.png 520w", "/static/d6bfeeee7e411168fcd3f931a11dcf3f/1ff84/mockup_libri.png 1040w", "/static/d6bfeeee7e411168fcd3f931a11dcf3f/08037/mockup_libri.png 1379w"],
                  "sizes": "(max-width: 1040px) 100vw, 1040px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span></p>
          </div>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{
              "width": "120px",
              "color": "#db4437",
              "cssFloat": "left"
            }} className="svg-inline--fa fa-circle-right d-md-inline-block"><path fill="currentColor" d="M512 256c0-141.4-114.6-256-256-256S0 114.6 0 256c0 141.4 114.6 256 256 256S512 397.4 512 256zM265.9 382.8C259.9 380.3 256 374.5 256 368v-64H160c-17.67 0-32-14.33-32-32v-32c0-17.67 14.33-32 32-32h96v-64c0-6.469 3.891-12.31 9.875-14.78c5.984-2.484 12.86-1.109 17.44 3.469l112 112c6.248 6.248 6.248 16.38 0 22.62l-112 112C278.7 383.9 271.9 385.3 265.9 382.8z"></path></svg>
            <Button solid inline large targetBlank color={"#db4437"} icon={faShare} href={"https://torredienna.it/salespage/index.html"} className={"mt-3 mb-1"} mdxType="Button">
    SCARICA File PDF Libro
            </Button>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{
              "width": "120px",
              "color": "#db4437",
              "cssFloat": "right"
            }} className="svg-inline--fa fa-circle-left d-none d-md-inline-block"><path fill="currentColor" d="M0 256c0 141.4 114.6 256 256 256s256-114.6 256-256c0-141.4-114.6-256-256-256S0 114.6 0 256zM246.1 129.2C252.1 131.7 256 137.5 256 144v64h96c17.67 0 32 14.33 32 32v32c0 17.67-14.33 32-32 32h-96v64c0 6.469-3.891 12.31-9.875 14.78c-5.984 2.484-12.86 1.109-17.44-3.469l-112-112c-6.248-6.248-6.248-16.38 0-22.62l112-112C233.3 128.1 240.1 126.7 246.1 129.2z"></path></svg>
          </div>
        </Section.FullWidth>
      </Card>
    </Container.Fluid>
    <Container.Fixed id="mappe" className="py-3 pb-lg-3">
      <Section.One>
        <Title type={"h3"} mdxType="Title">Schema Decumano e Cardini</Title>
        <hr></hr>
        <iframe title="Schema Decumano e Cardini" src="https://www.google.com/maps/d/embed?mid=11pC4XJMgCNjUtakDSGJZxbdXKuyk4Tfr" width="100%" height="480"></iframe>
      </Section.One>
      <Section.Two className="py-3 py-lg-0">
        <Title type={"h3"} mdxType="Title">Allineamento al solstizio d'estate</Title>
        <hr></hr>
        <iframe title="Allineamento al solstizio d'estate" src="https://www.google.com/maps/d/embed?mid=1TMUKhQn1GnLAWX-cQO-RG_Akqeffw-hA" width="100%" height="480"></iframe>
      </Section.Two>
    </Container.Fixed>
    <Container.Fluid id="gallery">
      <Section.FullWidth>
        <ImageGallery mdxType="ImageGallery" />
      </Section.FullWidth>
    </Container.Fluid>
    <Container.Fluid id="introduzione" className={"pt-4 pb-2"} background={"#ebe7db"} color={"#2c2e31"}>
      <Section.One className={"pr-lg-0"}>
        <iframe title="Torre di Enna" width="100%" height="315" src="https://www.youtube-nocookie.com/embed/5sxYZLkAhyk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Section.One>
      <Section.Two className={"py-3 py-lg-0"}>
        <Title type={"h3"} mdxType="Title">Nella città di Enna il capolavoro dell'arte Astronomica e Gromatica dei Siculi</Title>
        <hr></hr>
        <p>{`Il dibattito in merito all'origine e funzione primaria della Torre di Enna, comunemente detta "Torre di  Federico II di Svevia",
si è acceso con la pubblicazione del libro `}<strong parentName="p">{`“La verità sulla Torre ottagonale di Enna”`}</strong>{` (2018) di `}<strong parentName="p">{`Angelo Severino`}</strong>{`.`}</p>
        <p>{`Angelo Severino, partendo dalle indagini condotte intorno agli anni ’50 dal Colonnello Umberto Massocco e con l’ausilio di documenti
storici inediti, sostiene l'ipotesi secondo cui la fondazione della Torre ottagonale, modificata in età medievale, possa risalire ai
Siculi intorno all’Undicesimo secolo a.C., qualificando il monumento come il `}<strong parentName="p">{`primo (e forse l’ultimo superstite) osservatorio
astronomico-geodetico del Mediterraneo`}</strong>{`.`}</p>
      </Section.Two>
    </Container.Fluid>
    <Container.Fixed id="intervista" className={"pt-4 pb-2"}>
      <Section.One>
        <Title type={"h3"} mdxType="Title">Intervista inedita ad Angelo Severino</Title>
        <hr></hr>
        <blockquote>
          <ul parentName="blockquote">
            <li parentName="ul">
              <p parentName="li">{`Alla scoperta della Torre ottagonale di Enna`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Aspetti di astronomia a cura dell'ing. Liborio Petralia`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`L'archivio inedito dei documenti dell'autore`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Incremento turistico e culturale in Enna`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Conclusione e critiche finali`}</p>
            </li>
          </ul>
        </blockquote>
      </Section.One>
      <Section.Two order={"first"}>
        <iframe title="Intervista inedita ad Angelo Severino" width="100%" height="315" src="https://www.youtube-nocookie.com/embed/taRQSYXxow8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Section.Two>
    </Container.Fixed>
    <Container.Fluid id="collana" background="#444" color="#eee" className={"py-5"} style={{
      boxShadow: "inset 0 10px 10px -10px rgba(0,0,0,0.3)",
      letterSpacing: ".02em"
    }}>
      <Section.One col={"7"} order={"last"} className={"pb-3 pb-lg-0"}>
        <Title type={"h3"} style={{
          fontWeight: "500",
          fontSize: "215%",
          lineHeight: "120%",
          letterSpacing: "initial",
          color: "#f2ba4d"
        }} mdxType="Title">
Progetto <span style={{
            fontWeight: "800"
          }}>Collana Libri Storie di Sicilia</span>
        </Title>
        <hr></hr>
        <p><strong parentName="p">{`COLLANA LIBRI STORIE DI SICILIA`}</strong>{` è un progetto nato a Enna, luogo in cui si trova la famosa Torre ottagonale Hennaion (Umbilicus Siciliae et Trinakie)
ossia la costruzione più antica del mondo realizzata con finalità astronomiche e geodetiche che rappresenta il punto centrale del templum celeste di Sicilia.`}</p>
        <p>{`Il progetto è dedicata a tutti quelli che provano vero amore e profondo rispetto nei confronti della Sicilia.`}</p>
        <p>{`Si promuovono in maniera del tutto gratuita libri (anche auto-pubblicati), tesi di laurea, ricerche storiche e scientifiche, eventi culturali e pubblicazioni
sulla storia della Sicilia antica e recente.`}</p>
        <p>{`La pagina è un punto di riferimento per una vera comunità di ricerca libera e indipendente (non solo virtuale), finalizzata al bene del Popolo Siciliano.`}</p>
        <p>{`La nostra coesione sociale si basa sulla fiducia del lavoro di squadra, e sui valori di una vera famiglia composta da ricercatori liberi e indipendenti,
attivisti siciliani, poeti e scrittori sinceri.`}</p>
        <p>{`Con il vostro aiuto, noi ci impegniamo ogni giorno per costruire sinergia tra persone e idee, con l’obiettivo di riscrivere la vera storia della Sicilia
e far conoscere finalmente a tutto il mondo il suo reale valore storico-culturale.`}</p>
        <p>{`Crediamo che la cultura e la ricerca libera possa creare economia e benessere per la nostra bellissima Isola.`}</p>
        <p>{`Tu cosa ne pensi di questo progetto?`}</p>
      </Section.One>
      <Section.Two col={"5"} className="font-size-xsmall">
        <div style={{
          textAlign: "center"
        }}>
          <iframe title="Collana Libri Storie di Sicilia su Facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcollana.libri.storie.sicilia%2F&tabs&width=290&height=181&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="290" height="100%" style={{
            "border": "none",
            "overflow": "hidden"
          }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
        <Citazione image="foto_es.png" imageAlt="Enrico Sinicropi" mdxType="Citazione">
          <p>{`"Se nemici esistono contro Enna, rivolgo loro la preghiera
di non irridersi, almeno,
della scienza, dell'arte e della vita, che le diedero il volto immortale della bellezza e della eternità"`}</p>
          <p><em parentName="p">{`Enrico Sinicropi, Enna nella storia, nell'arte e nella vita, Arti grafiche Antonio Renna, Palermo 1958, p.108`}</em></p>
        </Citazione>
        <hr></hr>
        <Citazione image="foto_mk.png" imageAlt="Milan Kundera" mdxType="Citazione">
          <p>{`"Per liquidare i Popoli si comincia con il privarli della memoria.
Si distruggono i loro libri, la loro cultura, la loro storia. E qualcun altro scrive loro altri libri, li fornisce di un'altra cultura,
inventa per loro un'altra storia. Dopo di che il Popolo incomincia lentamente a dimenticare quello che è e quello che è stato.
E il mondo attorno a lui lo dimentica ancora più in fretta"`}</p>
          <p><em parentName="p">{`Milan Kundera`}</em></p>
        </Citazione>
      </Section.Two>
    </Container.Fluid>
    <Footer id="contatti" background={"#b6bbbc"} color={"#2c2e31"} mdxType="Footer">
      <Section.FullWidth className={"h4"}>
        <h3>{`Per informazioni, contatta il Project Manager`}</h3>
        <h4>{`Fabrizio Natanaele Severino`}</h4>
        <h4><a parentName="h4" {...{
            "href": "mailto:fabri8bit@gmail.com"
          }}>{`fabri8bit@gmail.com`}</a></h4>
      </Section.FullWidth>
      <Section.FullWidth>
        <hr></hr>
        <Copyright from="2017" mdxType="Copyright" />
        <p>{`Progettazione e Sviluppo `}<Anchor external href="https://www.zenitsecurity.com" title="Vai al sito di Zenit Security" mdxType="Anchor">{`Zenit Security`}</Anchor></p>
        <hr></hr>
        <p>{`Versione sito 2.0 - ultimo aggiornamento il 29 luglio 2024`}</p>
        <Anchor href="https://torredienna.it/extra/PrivacyPolicy.pdf" title={"Scarica il PDF con la Privacy Policy"} mdxType="Anchor">Privacy e Cookie Policy</Anchor>
        <Patamu mdxType="Patamu" />
      </Section.FullWidth>
    </Footer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React, { useEffect, useState, useRef } from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCaretDown} from "@fortawesome/free-solid-svg-icons"

import Share from "../components/share.js"
import LogoImg from "../images/logo_torre.svg"
import Anchor from "../components/anchor.js"
// import {UnstyledButton} from "./button"

const HeaderWrapper = styled.nav`
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;    
    
  section {
    height: ${props => props.theme.header.height};
    align-items: center;
    justify-content: space-between;
    transition: height 0.3s ease;
    .logo {
        a {
            text-decoration: none;
            color: ${props => props.theme.color.black}
        }
    }
    .nav-m {
      :focus {
        outline: none;
      }
      .menu_label {
        display: block;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
      }
      &.open {
        button {
          div {
            background: ${props => props.theme.color.grayonaltwhite};
            &.second {
              opacity: 0;
            }
            &.first {
              transform: rotate(45deg);
              top: .95rem;
            }
            &.third {
              transform: rotate(-45deg);
              bottom: .4rem;
            }
          }
        }
      }
      button {
        height: 1.5rem;
        width: 3.1rem;
        position: relative;
        background: transparent;
        padding: 0;
        border: none;
        div {
          transition: all 0.2s;
          height: 3px;
          width: 100%;
          background: ${props => props.theme.color.grayonaltwhite};
          position: absolute;          
          &.first {
            top: 0;
            left: 0;
          }
          &.second {
            top: calc(50% - 1.5px);
            left: 0;
          }
          &.third {
            bottom: 0;
            left: 0;
          }
        }
      }
    }
    .nav-d {
      display: none;
      span {
        color: ${props => props.theme.color.gray};
        font-size: .9rem;
        line-height: 2.5rem;
        font-weight: 500;
        margin-right: 3px;
      }    
      a, button {
        color: ${props => props.theme.color.black};
        font-size: .9rem;
        line-height: 2.2rem;
        font-weight: ${props => props.theme.font.medium};
        margin-left: .1rem;
        margin-bottom: .4rem;
        position: relative;
        text-decoration: none;
        transition: all 0.3s ease;
        padding: 0.2rem 0.5rem;
        z-index: 10;
        &:hover {
            color: white;
          &::before {
            height: 100%;
            width: 100%;
            opacity: 1;
            border-radius: 8px;
          }
        }
        &:before {
          z-index: -1;
          content: '';
          position: absolute;
          height: 0%;
          width: 100%;
          bottom: 0;
          left: 0;
          opacity: 1;
          transition: all 0.3s ease;
          background: ${props => props.theme.color.gray};
        }
      }
      .accent-link-menu {
        background-color: #59a139;
        color: #fff!important;
        border-radius: 8px;
      }
      .accent-link-menu-2 {
        background-color: #4d6a79;
        color: #fff!important;
        border-radius: 8px;
      }
      .divider {
        position: relative;
        margin-right: 2rem;
        &::after {
          content: '';
          height: 3rem;
          width: 0.1rem;
          background: ${props => props.theme.color.grayonaltwhite};
          position: absolute;
          top: -0.3rem;
          right: -1rem;
        }
      }
    }
    .nav-mm {
      align-items: center;
    
      font-size: 1.2rem;
      line-height: 2rem;
      text-transform: uppercase;
      transform: translateX(${props => (props.open ? '0%' : '100%')});
      transition: transform 0.3s ease;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: ${props => props.theme.header.height};
      left: 0;
      width: 100%;
      background: white;
      height: calc(100vh - ${props => props.theme.header.height});
      padding: 1.1rem;
      @media (min-width: ${props => props.theme.screen.sm}) {
        padding: 0 2.2rem;
      }
      a {
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.extrabold};
        color: ${props => props.theme.color.black};
        margin-bottom: 0.2rem;
        text-decoration: none;
        &.divider {
          margin-bottom: 2rem;
          position: relative;
          z-index: -99;
          &:after {
            content: '';
            position: absolute;
            bottom: -1rem;
            left: 0;
            width: 100%;
            height: 0.2rem;
            background: ${props => props.theme.color.grayonaltwhite};
          }
        }
      }
      .accent-link-menu {
        background-color: #59a139;
        color: #fff!important;
        padding: 5px 10px;
        border-radius: 8px;
        text-transform: uppercase;
        font-family: ${props => props.theme.font.secondary};
        font-weight: ${props => props.theme.font.extrabold};
        color: ${props => props.theme.color.black};
        margin-bottom: 0.2rem;
      }
      .accent-link-menu-2 {
        background-color: #4d6a79;
        color: #fff!important;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 8px;
      }
    }
    @media (min-width: ${props => props.theme.screen.lg}) {
      .nav-m {
        display: none;
      }
      .nav-d {
        display: flex;
      }
      .nav-mm {
        display: none;
      }
    }
  }
`;

const StyledAnchor = styled(Anchor)`
  background-color: #59a139!important;
  color: #fff!important;
  padding: 5px 10px;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: ${props => props.theme.font.secondary};
  font-weight: ${props => props.theme.font.extrabold};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.2rem;
`;

const HeaderMenuMask = styled.div`
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  width: ${props => (props.triggered ? '100%' : '0%')};
  transition: 0.3s all;
  z-index: -1;
  height: 100%;
`;

const HeaderScrolledMask = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.3s ease;
  z-index: -1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: ${props => (props.triggered ? '100%' : '0%')};
`;

const StyledLogo = styled.img`
  width: 189px;
  height: 58px;
`;

const Logo = () => <><StyledLogo src={LogoImg}  alt={"Il sito della Torre di Enna"} /></>;

const Container = ({ children }) => (
    <section className="container d-flex">{children}</section>
);


// Hook for handling closing when clicking outside of an element
const useDetectOutsideClick = (el, initialState) => {
    const [isActive, setIsActive] = useState(initialState);

    useEffect(() => {
        const onClick = e => {
            // If the active element exists and is clicked outside of
            if (el.current !== null && !el.current.contains(e.target)) {
                setIsActive(!isActive);
            }
        };

        // If the item is active (ie open) then listen for clicks outside
        if (isActive) {
            window.addEventListener("click", onClick);
        }

        return () => {
            window.removeEventListener("click", onClick);
        };
    }, [isActive, el]);

    return [isActive, setIsActive];
};

const Menu = ({ location }) => {
    const [open, setOpen] = useState(false);

    const scrollY = useScrollYPosition();
    const scrolled = scrollY !== 0;

    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    useEffect(() => {
        setOpen(false);
    }, [location, scrollY]);

    function renderLinks() {
        const URL = typeof window !== "undefined" ? window.location.href : '';
        return (
            <>
                {/*{(typeof window !== "undefined" && !window.location.pathname.includes('privacy')) ?*/}
                    <>
                        <AnchorLink to="/#top" stripHash>Home</AnchorLink>
                        <AnchorLink to="/#progetto" stripHash>Progetto</AnchorLink>

                        <AnchorLink to="/#mappe" stripHash>Mappe</AnchorLink>

                        {/*<div className="menu-container">*/}
                        {/*    <button onClick={onClick} className="menu-trigger accent-link-menu">*/}
                        {/*        Scarica PDF*/}
                        {/*        <FontAwesomeIcon icon={faCaretDown} className={"ml-1"} style={{width:".625em"}} />*/}
                        {/*    </button>*/}
                        {/*    <nav*/}
                        {/*        ref={dropdownRef}*/}
                        {/*        className={`menu ${isActive ? "active" : "inactive"}`}*/}
                        {/*    >*/}
                        {/*        <ul>*/}
                        {/*            /!*<li style={{whiteSpace: "nowrap"}}>*!/*/}
                        {/*            /!*    <AnchorLink to="/#email-download-pdf" stripHash>Allegati del libro</AnchorLink>*!/*/}
                        {/*            /!*</li>*!/*/}
                        {/*            <li style={{whiteSpace: "nowrap"}}>*/}
                        {/*                <AnchorLink to="/#indice" stripHash>Indice del libro</AnchorLink>*/}
                        {/*            </li>*/}
                        {/*            <li style={{whiteSpace: "nowrap"}}>*/}
                        {/*                <AnchorLink to="/#articoli_pdf" stripHash>Articoli di giornale</AnchorLink>*/}
                        {/*            </li>*/}
                        {/*            <li style={{whiteSpace: "nowrap", height: "50px"}}>*/}
                        {/*                <Anchor  href="https://torredienna.it/extra/luoghi_federico_secondo_svevia_enna_sicilia.pdf">Federico II</Anchor>*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </nav>*/}
                        {/*</div>*/}

                       {/* <AnchorLink to="/#libro" className={"accent-link-menu-2"} stripHash>Compra il libro</AnchorLink>*/}

                    
                      <Anchor href="https://torredienna.it/salespage/index.html" style={{ display: "inline-block", backgroundColor: "#db4437", color: "#fff", borderRadius: "10px" }}>Compra il libro</Anchor>
                      
                    <AnchorLink to="/#autori" stripHash>Autori</AnchorLink>
                        <AnchorLink to="/#collana" stripHash>Collana</AnchorLink>
                    <AnchorLink to="/#contatti" className="divider" stripHash>Contatti</AnchorLink>


                        {/*<Link className="divider" to={'/privacy'}>Privacy Policy</Link>*/}
                        <span>Condividi:</span>
                        <Share url={URL} />
                    </>
                    {/*:*/}
                    {/*<AnchorLink to="/#top" stripHash>Torna alla Home</AnchorLink>*/}
                {/*}*/}
            </>

        );
    }

    return (
        <HeaderWrapper triggered={scrolled} open={open}>
            <HeaderMenuMask triggered={open} />
            <HeaderScrolledMask triggered={scrolled} />
            <Container>
                <div className="logo">
                    <AnchorLink to="/#top" stripHash>
                        {/*<span className={"mr-2 mr-xl-0 d-block d-sm-inline-block d-xl-block h4"}>Collana Libri</span>*/}
                        {/*<span className={"d-block d-sm-inline-block d-xl-block h3"}>Storie di Sicilia</span>*/}
                        <Logo />
                    </AnchorLink>
                </div>
                <div className="nav-d">{renderLinks()}</div>
                <div className={`nav-m${open ? ' open' : ''}`} role="button" tabIndex={0} onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>
                    <span className="menu_label">MENU</span>
                    <button
                        aria-label="Toggle Menu"
                        className="icon"
                    >
                        <div className="first" />
                        <div className="second" />
                        <div className="third" />
                    </button>
                </div>
                <div className="nav-mm">{renderLinks()}</div>
            </Container>
        </HeaderWrapper>
    );
};

export default Menu;